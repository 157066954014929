var scroll = new SmoothScroll('a[href*="#"]', {
    speed: 800
});
const myAtropos = Atropos({
    el: '.my-atropos',
    // rest of parameters
});
setTimeout(function(){
    $(function () {
        $('#WebsiteLoader').css('display', 'none');
        $('#WebsiteLoader').addClass('tw-d-none');
    });
},1000);
