require('./bootstrap');

require('./arkan-ar-old');

window.$ = window.jQuery = require('jquery');
import SmoothScroll from "smooth-scroll";
window.SmoothScroll = SmoothScroll;
import { themeChange } from 'theme-change';
themeChange();
import Glide from '@glidejs/glide';
window.Glide = Glide;
import Atropos from 'atropos';
window.Atropos = Atropos;
require('./isotope');
require('./ownjs');
import Swal from "sweetalert2";
import QRCodeStyling from "qr-code-styling";
window.QRCodeStyling=QRCodeStyling;
window.Swal = Swal;
